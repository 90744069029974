import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import LabelText from '../../components/LabelText';
import Cards from '../../components/Cards';
import api from '../../common/api';
import {CommonCodeValue, ErrCode} from '../../common/types';
import moment from 'moment/moment';
import _ from 'lodash';
import {makeStyles} from '@material-ui/core/styles';
import InputTexts from '../../components/InputTexts';
import Button from '@material-ui/core/Button';
import MobxReactForm from 'mobx-react-form';
import dvr from 'mobx-react-form/lib/validators/DVR';
import validatorjs from 'validatorjs';
import {useStores} from '../../common/store';
import {useHistory} from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        padding: '1.875rem',
    },
    title: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(5),
        color: '#fff',
        backgroundColor: '#171E31',
        fontSize: '1.125rem',
        display: 'flex',
        alignItems: 'center',
    },
    content: {
        paddingBottom: theme.spacing(3),
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
    },
    bodyContainer: {
        display: 'flex',
        flexDirection: 'column',
        '& > div': {
            marginBottom: theme.spacing(2),
        },
        '& > div:first-child': {
            minWidth: 520,
            [theme.breakpoints.between('xs', 'md')]: {
                minWidth: 'initial',
                flex: 1,
            },
        },
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& > *': {
            width: 130,
            height: 40,
            borderRadius: 5,
            fontSize: '0.75rem',
            fontWeight: 700,
            boxShadow: 'none',
        },
        '& > :first-child': {
            border: '1px solid #C7CBD1',
            backgroundColor: 'transparent',
            color: '#81808A',
            marginRight: 6,
            '&:hover': {
                border: '1px solid #3C39CB',
                backgroundColor: 'transparent',
                color: theme.palette.primary.main,
            },
        },
        '& > :last-child': {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            '&:hover': {
                backgroundColor: theme.palette.secondary.main,
                color: '#fff',
            },
        },
    },
}));


export default function LicenseUpdate(props) {
    const classes = useStyles();
    const { ds } = useStores();
    const { t } = useTranslation();
    const history = useHistory();
    const { onClose, clientKey, licenseKey: initialLicenseKey, seq } = props;
    const [licenseKey, setLicenseKey] = useState('');

    const [openConfirm, setOpenConfirm] = useState(false);
    const [showLicenseNext, setShowLicenseNext] = React.useState(false);
    const [apiResponse, setApiResponse] = useState(null);

    const [showForm, setShowForm] = useState(true);
    const [showLicenseSave, setShowLicenseSave] = useState(false);
    const [open, setOpen] = useState(true);

    const handleNextButtonClick = () => {
        getLicenseUpdateData();
    };

    const getLicenseUpdateData = () => {
        const formLicenseKey = form.$('content').value;

        api.getLicenseUpdate(formLicenseKey, clientKey)
            .then((data) => {
                if (data.rtCode === ErrCode.RT_SUCCESS) {
                    setApiResponse(data.data.changeLicense);
                    setShowLicenseNext(true);
                    setShowForm(false); // Hide the form
                    setShowLicenseSave(true); // Show LicenseUpdateSave
                } else {
                    // Handle the case when data is not successful
                    ds.showErrorAlert(api.getErrMsg(data.rtCode));
                }
            })
            .catch((err) => {
                // Handle other errors
                ds.showErrorAlert(api.getErrMsg(err.rtCode));
            });
    };

    const handleSaveButtonClick = () => {
        putLicenseUpdate(apiResponse);
        handleClose();
    };


    const handleClose = () => {
        setOpen(false);  // Close the main dialog
    };

    const putLicenseUpdate = (requestData) => {
        api.putLicenseUpdate(requestData)
            .then((data) => {
                if (data.rtCode === ErrCode.RT_SUCCESS) {
                    // revokeOpenHandler(false);
                    ds.showInfoAlert(t('LicenseDetail.updateSaveDialog.UpdateSuccess'));
                    history.push('/admin/license');
                }
            })
            .catch((error) => {
                ds.showErrorAlert(api.getErrMsg(err.rtCode));
            });
    };

    const formId = 'confirmForm';

    const form = new MobxReactForm(
        {
            fields: [
                {
                    name: 'content',
                    value: licenseKey,
                    rules: 'required',
                    label: t('LicenseDetail.confirmDialog.LicenseKey'),
                },
            ],
        },
        {
            plugins: {
                dvr: dvr(validatorjs),
            },
            hooks: {
                onSuccess(form) {
                    const values = form.values();
                    if (values['content'] !== null && values['content'] !== '') {
                        setLicenseKey(values['content']);
                    }
                    setOpenConfirm(true);
                },
                onError(form) {
                    const errors = form.errors();
                    let errorMsg = '';
                    Object.keys(errors).map((data) => {
                        if (errorMsg === '') {
                            errorMsg = errors[data];
                            if (errorMsg === null) {
                                errorMsg = '';
                            }
                        }
                    });
                    ds.showErrorAlert(errorMsg);
                },
            },
        }
    );

    useEffect(() => {
        if (showLicenseNext) {
            setOpenConfirm(false);
        }
    }, [showLicenseNext]);

    useEffect(() => {
        form.update({
            content: licenseKey,
        });
    }, [licenseKey]);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle className={classes.title}>{t('LicenseDetail.confirmDialog.ConfirmTitle')}</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                {showForm && (
                    <form noValidate autoComplete="off" onSubmit={form?.onSubmit} id={formId}>
                        <div className={classes.bodyContainer}>
                            <div>
                                <InputTexts
                                    name={'content'}
                                    form={form}
                                    label={t('LicenseDetail.confirmDialog.LicenseKey')}
                                    rows={10}
                                    variant={'outlined'}
                                />
                            </div>
                        </div>
                    </form>
                )}
                {!showForm && showLicenseNext && showLicenseSave && (
                    <div>
                        <LicenseUpdateSave seq={seq} licenseData={apiResponse}/>
                    </div>
                )}
                {!showForm && !showLicenseSave && (
                    <div className={classes.buttonContainer}>
                        <Button variant="contained" type={'button'} onClick={onClose}>
                            {t('LicenseDetail.confirmDialog.Cancel')}
                        </Button>
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            onClick={handleNextButtonClick}
                        >
                            {t('LicenseDetail.confirmDialog.Next')}
                        </Button>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <div className={classes.buttonContainer}>
                    <Button variant="contained" type={'button'} onClick={onClose}>
                        {t('LicenseDetail.confirmDialog.Cancel')}
                    </Button>
                    {showLicenseNext && showLicenseSave && (
                        <Button variant="contained" type={'button'} onClick={handleSaveButtonClick}>
                            {t('LicenseDetail.confirmDialog.Save')}
                        </Button>
                    )}
                    {!showLicenseNext && !showLicenseSave && (
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            onClick={handleNextButtonClick}
                        >
                            {t('LicenseDetail.confirmDialog.Next')}
                        </Button>
                    )}
                </div>
            </DialogActions>
        </Dialog>
    );
}

export function LicenseUpdateSave({seq: updateSeq, licenseData: licenseData}) {
    const {t} = useTranslation();
    const [localLicenseData, setLocalLicenseData] = useState(null);
    const [originalData, setOriginalData] = useState(null);
    const {ds} = useStores();

    let client = null;
    let vendor = null;
    let contract = null;
    let quota = null;
    let period = null;
    let threshold = null;
    let createDt = null;

    const search = () => {
        if (updateSeq) {
            setLocalLicenseData(licenseData);
            api.getLicenseDetail(updateSeq)
                .then((data) => {
                    if (data.rtCode === ErrCode.RT_SUCCESS) {
                        setOriginalData(data.data);
                    }
                })
                .catch((error) => {
                    ds.showErrorAlert(t('Error.' + data.rtCode));
                });
        }
    };


    useEffect(() => {
        search();
    }, [licenseData, updateSeq]);

    if (localLicenseData !== null && originalData !== null) {
        client = originalData.clientName ?? '-';
        vendor = originalData.vendorClientKey ?? '-';
        contract = t(CommonCodeValue.translateKey + '.' + (localLicenseData.contractType ?? '-'));
        period = localLicenseData.startDt + ' ~ ' + localLicenseData.endDt;
        quota = t(CommonCodeValue.translateKey + '.' + (localLicenseData.quotaType ?? '-'));
        threshold = localLicenseData.maxQuota;
        createDt = _dataFormat(localLicenseData?.createDt, 'date', 'YYYY-MM-DD HH:mm:ss' || null);
    }

    const specificationsInfo = [
        { label: 'LicenseDetail.Client', name: 'clientName', component: LabelText, value: client, translation: true },
        { label: 'LicenseDetail.Vendor', name: 'vendorClientName', component: LabelText, value: vendor, translation: true },
        { label: 'LicenseDetail.Contract', name: 'contract', component: LabelText, value: contract, translation: true },
        { label: 'LicenseDetail.QuotaType', name: 'quota', component: LabelText, value: quota, translation: true },
        { label: 'LicenseDetail.Threshold', name: 'threshold', component: LabelText, value: threshold, translation: true },
        { label: 'LicenseDetail.Period', name: 'period', component: LabelText, value: period, translation: true },
        { label: 'LicenseDetail.CreateDt', name: 'createDt', component: LabelText, value: createDt, translation: true },

    ];

    return (
        <div style={{width: '500px'}}>
            <Cards components={specificationsInfo}/>
        </div>
    );
}

function _dataFormat(data, dataType, format) {
    if (data === undefined || data === null) {
        return null;
    }
    if (dataType === 'date') {
        let dateFormat = 'YYYY-MM-DD';
        if (format !== undefined) {
            dateFormat = format;
        }
        return moment(data, dateFormat).format(dateFormat);
    } else if (dataType === 'number') {
        if (_.isNumber(data)) {
            return new Intl.NumberFormat().format(data);
        }
    }
    return data;
}
